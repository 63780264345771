@import '$extensionSrc/sass/consts';

.BatchRow {
  align-items: center;
  display: flex;
  gap: $spacer;
  padding: $spacer 0;
  position: relative;

  .status-icon {
    min-height: 20px;
    min-width: 20px;
  }

  .review-button {
    display: flex;
    flex-direction: column;

    position: absolute;
    right: 0;
    width: 144px;
  }
}
