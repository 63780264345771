@import '$extensionSrc/sass/consts';

.HomePageMetrics {
  .MetricsRow {
    display: flex;
    flex-direction: row;
    gap: 3 * $spacer;
    justify-content: center;
    margin-top: 3 * $spacer;

    .MetricsBox {
      align-items: center;
      border: 1px solid $black;
      display: flex;
      flex-basis: 100%;
      flex-direction: column;
      gap: 2 * $spacer;
      max-width: 400px;
      padding: 2 * $spacer;

      .this-week {
        display: flex;
        flex-direction: column;
        font-size: 60px;
        gap: $spacer;
        text-align: center;
      }
    }
  }
}
