/* red-hat-text-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 400;
  src:
    url('moz-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-regular.woff2')
      format('woff2'),
    url('chrome-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-regular.woff2')
      format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* red-hat-text-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Red Hat Text';
  font-style: italic;
  font-weight: 400;
  src:
    url('moz-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-italic.woff2')
      format('woff2'),
    url('chrome-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-italic.woff2')
      format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* red-hat-text-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Red Hat Text';
  font-style: normal;
  font-weight: 600;
  src:
    url('moz-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-600.woff2')
      format('woff2'),
    url('chrome-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-600.woff2')
      format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* red-hat-text-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Red Hat Text';
  font-style: italic;
  font-weight: 600;
  src:
    url('moz-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-600italic.woff2')
      format('woff2'),
    url('chrome-extension://__MSG_@@extension_id__/fonts/red-hat-text-v13-latin-600italic.woff2')
      format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* red-hat-text-regular */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    url('moz-extension://__MSG_@@extension_id__/fonts/roboto-regular.woff2')
      format('woff2'),
    url('chrome-extension://__MSG_@@extension_id__/fonts/roboto-regular.woff2')
      format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
