@import '../sass/consts';

.ActionButton {
  &.btn.override {
    align-items: center;
    border: 0;
    border-radius: 20px;
    display: flex;
    font-size: 12px;
    font-weight: bold;
    gap: $spacer;
    height: 36px;
    justify-content: center;
    letter-spacing: 1px;
    line-height: normal; // explicitly set, to override bootstrap defaults
    overflow: hidden;
    padding: 10px 24px;
    text-transform: uppercase;

    &.has-icon {
      display: inline-block;
      position: relative;

      .btn-icon {
        height: 100%;
        left: 0;
        // padding is asymmetrical because it makes the icon more centered
        padding: 0 6px 0 10px;
        position: absolute;
        top: 0;
      }

      .bordered {
        border-right: 1px solid $lighterGray;
      }
    }

    &.icon-only {
      .btn-icon {
        height: 100%;
        width: 100%;
      }
    }

    &.extra-small {
      border-radius: 18px;
      font-size: 12px;
      height: 28px;
      line-height: 100%;
      padding-bottom: 0;
      padding-top: 0;

      &.has-icon {
        .btn-icon {
          width: 28px;
        }
      }

      &.icon-only {
        flex-shrink: 0; // it's already small - don't shrink it
        padding: 5px;
        width: 28px;
      }
    }

    &.small {
      border-radius: 20px; // height - padding = 36 - 20
      font-size: 12px;
      height: 36px;

      &.has-icon {
        .btn-icon {
          width: 36px;
        }
      }

      &.icon-only {
        padding: 6px;
        width: 36px;
      }
    }

    &.medium {
      border-radius: 24px; // height - padding = 44 - 20
      font-size: 14px;
      height: 44px;

      &.has-icon {
        .btn-icon {
          width: 44px;
        }
      }

      &.icon-only {
        padding: 7px;
        width: 44px;
      }
    }

    &.large {
      border-radius: 28px; // height - padding = 48 - 20
      font-size: 14px;
      height: 48px;

      &.has-icon {
        .btn-icon {
          width: 48px;
        }
      }

      &.icon-only {
        padding: 8px;
        width: 48px;
      }
    }

    // Styles
    &.btn-primary {
      // Base
      background-color: $purple;
      color: $white;

      &:hover,
      &:focus {
        background-color: $darkPurple;
        color: $white;
      }

      &:disabled {
        background-color: $lighterPurple;
      }

      // Checkmark icon
      .checkmark-icon {
        background-color: $green;
      }

      &:hover,
      &:focus {
        .checkmark-icon {
          background-color: $darkerGreen;
        }
      }

      &:disabled {
        .checkmark-icon {
          background-color: $green;
        }
      }

      // Settings check icon
      .settings-check-icon {
        background-color: $lighterPurple;
      }

      &:hover,
      &:focus {
        .settings-check-icon {
          background-color: $lighterPurple;
        }
      }

      &:disabled {
        .settings-check-icon {
          background-color: $lightestPurple;
        }
      }

      .upgrade-lock-icon {
        background-color: $lightPurple;
        padding: unset;
        transition: background-color 0.15s ease-in-out;

        path {
          fill: $white;
        }
      }

      &:hover,
      &:focus {
        .upgrade-lock-icon {
          background-color: $purple;
        }
      }

      &:disabled {
        .upgrade-lock-icon {
          background-color: $lightestPurple;
        }
      }

      &.has-icon {
        .bordered {
          border-right: 1px solid $lightPurple;
        }
      }
    }

    &.btn-primary-green {
      // Base
      background-color: $darkerGreen;
      color: $white;

      &:hover,
      &:focus {
        background-color: $darkestGreen;
        color: $white;
      }

      &:disabled {
        background-color: $lightGreen;
      }

      // Checkmark icon
      .checkmark-icon {
        background-color: $green;
      }

      &:hover,
      &:focus {
        .checkmark-icon {
          background-color: $darkerGreen;
        }
      }

      &:disabled {
        .checkmark-icon {
          background-color: $lighterGreen;
        }
      }

      &.has-icon {
        .bordered {
          border-right: 1px solid $lightPurple;
        }
      }
    }

    &.btn-secondary {
      background-color: $white;
      border: 1px solid $lighterGray;
      color: $purple;

      &:hover:not(:disabled),
      &:focus:not(:disabled) {
        background-color: $almostWhite;
        border: 1px solid $lighterPurple;
        color: $darkPurple;
      }

      &:disabled {
        background-color: $white;
        color: $lighterGray;
      }

      &.row {
        border-radius: 0;
      }
    }

    &.btn-tertiary {
      align-items: center;
      background-color: transparent;
      color: $darkerGray;
      display: flex;
      gap: 4px;
      justify-content: center;
      padding-left: $spacer;
      padding-right: $spacer;

      &:hover,
      &:focus {
        background-color: $lightestGray;
        color: $darkestGray;
      }

      &:disabled {
        background-color: transparent;
      }

      &.extra-small,
      &.small,
      &.medium,
      &.large {
        .btn-icon {
          padding: 0;
          position: relative;
          width: unset;
        }
      }
    }

    &.btn-warning {
      background-color: $yellow;
      color: $black;

      &:hover,
      &:focus {
        background-color: $darkYellow;
        color: $black;
      }

      &:disabled {
        background-color: $lighterYellow;
        color: $gray;
      }
    }

    &.btn-danger {
      background-color: $darkRed;
      color: $white;

      &:hover {
        background-color: $darkerRed;
        color: $white;
      }

      &:disabled {
        background-color: $lighterRed;
        color: $white;
      }

      &.has-icon {
        .cancel-icon {
          background-color: rgba(0, 0, 0, 0.1);
          color: $white;
        }
      }

      .upgrade-lock-icon {
        background-color: $red;
        padding: unset;
        transition: background-color 0.15s ease-in-out;

        path {
          fill: $white;
        }
      }

      &:hover,
      &:focus {
        .upgrade-lock-icon {
          background-color: $darkRed;
        }
      }

      &:disabled {
        .upgrade-lock-icon {
          background-color: $lighterRed;
        }
      }
    }
  }
}
