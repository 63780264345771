@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/tooltip';

.SettingRow {
  margin: $spacer 0;

  .setting-icon-wrapper {
    display: flex;
  }

  .setting-icon {
    height: 20px;
    margin-right: $spacer;
    min-width: 20px;

    &.less-relevant {
      path {
        stroke: $darkerGreen;
      }
    }
  }

  .setting-text-wrapper {
    gap: 0.5 * $spacer;

    .SidebarLink {
      display: contents;

      .link-text {
        border-bottom: 1px solid $darkerBlue;
        color: $black;
        display: inline;
        padding: 0 1px;

        &:visited {
          color: $black;
        }

        &:hover {
          background: $lightBlue;
          border-bottom: 1px solid $darkerBlue;
          color: $black;
        }
      }

      .link-arrow {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .setting-label {
      > p {
        margin-bottom: 0;
      }
    }

    .setting-description-wrapper {
      display: flex;
      flex-direction: row;
    }

    .cant-undo-warning {
      bottom: 2px; // to put icon in line with rest of text
      display: inline;
      position: relative;

      .warning-icon {
        height: 18px;
        min-width: 18px;
      }

      .tooltip-text {
        @include tooltip-text('', '');
        margin-bottom: 0.5 * $spacer;
        right: -180px;
        top: 24px;
        z-index: 10;
      }

      &:hover {
        .tooltip-text {
          visibility: visible;
        }
      }
    }
  }
}
