@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/recommendation-card-body';
@import '$extensionSrc/sass/tooltip';

.taskBody {
  @include recommendation-transition;
}

.recommendationBody {
  @include recommendation-body;

  ul {
    list-style-type: none;
    padding-left: $spacer;

    li::before {
      content: '•';
      display: inline-block;
      width: $spacer * 1.5;
    }
  }
}

.recommendationFooter {
  @include recommendation-footer;

  .footer-description {
    justify-content: space-between;
    text-align: left;
  }

  .footer-buttons {
    gap: $spacer * 2;
  }

  .tooltip-text {
    @include tooltip-text('right', 'above');
    bottom: $spacer * 7;
    max-width: $spacer * 40;
    right: $spacer * 2;
    white-space: wrap;
    z-index: 10;
  }

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}

.taskButton {
  display: flex !important;
}
