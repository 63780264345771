@import '$extensionSrc/sass/consts';

.banner {
  background-image: $bluePurple;
  border-bottom: 1px solid $lighterGray;
  display: flex;
  flex-direction: column;
  gap: $spacer;
  padding: 2 * $spacer;
}

.bannerHeader {
  align-items: start;
  display: flex;
  flex-direction: row;
  gap: $spacer * 2;
  justify-content: space-between;
}

.closeButton {
  svg > path {
    fill: $lightGray;
  }

  &:global(.ActionButton.btn.override.btn-tertiary) {
    &:hover,
    &:focus {
      background: $black05;

      svg > path {
        fill: $darkerGray;
      }
    }
  }
}

.footer {
  border-top: 1px solid $darkerBlue;
  padding-top: 8px;
}
