// Colors

// -- Brand  -- //

$lightBlue: #f0f7fa;
$blue: #e9f5f7;
$darkBlue: #d8ebf2;
$darkerBlue: #a6cddc;
$darkestBlue: #7bb7cf;

$lightestPurple25: #e9e1f840;
$lightestPurple: #e9e1f8;
$lighterPurple: #d6c7f0;
$lightPurple: #6a4ebf;
$purple: #5b1dc2;
$darkPurple: #4b18a1;
$magicPurple: #9d68f4;

// -- Base -- //

$lightestGray: #f5f5f5;
$lighterGray: #eaeaea;
$lightGray: #ccc;
$gray: #a6a6a6;
$darkGray: #808080;
$darkerGray: #606060;
$darkestGray: #404040;

$white: #fff;
$almostWhite: #f9f9f9;
$almostBlack: #15131f;
$black: #000;

$transparentWhite: rgba(#fff, 0.85);
$moreTransparentWhite: rgba(#fff, 0.5);
$transparentBlack: rgba(#000, 0.85);
$moreTransparentBlack: rgba(#000, 0.75);
$transparentGray: rgba(#000, 0.25);
$moreTransparentGray: rgba(#000, 0.08);
$black05: rgba(#000, 0.05);
$black10: rgba(#000, 0.1);
$black70: rgba(#000, 0.7);
$evenMoreTransparentGray: rgba(#000, 0.04);
$transparentBlue: rgba(#e9f5f7d9, 0.85);
$darkestBlue30: rgba(123, 183, 207, 0.3);

// -- Contextual -- //

$lighterGreen: #c6eab8;
$lightGreen: #b4e3a1;
$green: #81cf61;
$darkGreen: #6ac643;
$darkerGreen: #42b412;
$darkestGreen: #3b9515;

$lighterYellow: #fdf0c6;
$lightYellow: #ffeaa7;
$yellow: #ffd54f;
$darkYellow: #ffc200;

$lighterRed: #fbe3e3;
$lightRed: #ff989d;
$red: #fd868c;
$darkRed: #ed5e5e;
$darkerRed: #d43838;

// -- Tasks -- //

$lightestOrange: #ffead7;
$lighterOrange: #ffdbba;
$lightOrange: #febf84;
$orange: #ff9c43;
$darkOrange: #ff8313;

// -- Non Block Party Colors -- //

$appleMailBlue: linear-gradient(#1e51ee, #19e6ff);

// -- Gradients -- //
$foamLightestPurple: linear-gradient(135deg, #dffee6 0%, #e9e1f8 100%);
$orangePurple: linear-gradient(92deg, #ffead7 0%, #e9e1f8 100%);
$bluePurple: linear-gradient(90deg, #e7f8ff 0%, #e3e1ff 100%);
$almondPeach: linear-gradient(90deg, #fdf0c6 0%, #ed5e5e 275%);

// Fonts

$fontFamily: 'Red Hat Text', 'Helvetica', sans-serif;

// Spacing

$spacer: 8px;

// Z-Indices

$overlayZIndex: 10000000;
$overlayModalZIndex: $overlayZIndex + 1;
$sidebarHeaderZIndex: 1;
$sidebarOverlayZIndex: $sidebarHeaderZIndex + 1;
$toastZIndex: $overlayModalZIndex + 1;
$headerZIndex: 1;
$modalZIndex: $headerZIndex + 10;

// Sizing

$dashboardHeaderHeight: 56px;
$dashboardNavWidth: 342px;
$dashboardNotificationPanelWidth: 332px;
$sidebarHeaderHeight: 65px;
$sidebarWidth: 400px;

$sidebarHeaderFilterHeight: 50px;
$maxSidebarContainerTop: 72px;

// Dashboard

$dashboardPreviewToolTipZIndex: 2;

// Sidebar2

$sidebar2FilterBarZIndex: 2;
$sidebar2HeaderHeight: $spacer * 8 + 1px;
$sidebar2HeaderZIndex: 4;
$sidebar2DrawerZIndex: 3;
$sidebar2ModalZIndex: 5;
$sidebar2Width: 436px;
$sidebar2DrawerWidth: 37px;

// Transition

// The stylelint-disable line just below is because stylelint is in conflict
// with how Prettier formats this code
/* stylelint-disable scss/dollar-variable-colon-space-after */
$transition:
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out,
  color 0.15s ease-in-out,
  opacity 0.15s ease-in-out,
  outline 0.15s ease-in-out;
