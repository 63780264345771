@import '../sass/consts';

.fbUnfriendCheckbox {
  background: $lightestPurple;
  flex: 0 0 $spacer * 2.5;
  height: $spacer * 2.5;
  transition: $transition;
  width: $spacer * 2.5;

  :global(svg) {
    height: $spacer * 2.5;
    width: $spacer * 2.5;
  }

  &.checked {
    background: $green;

    :global(.border) {
      display: none;
    }

    :global(.interior) {
      fill: $green;
    }

    :global(.checkmark) {
      fill: $white;
    }
  }

  &.friend {
    background: $white;
    border: 1px solid $lightGray;
    border-radius: 1px;

    :global(svg) {
      margin-left: -1px;
      margin-top: -1px;
    }
  }

  &.friend.checked {
    background: transparent;

    :global(.interior) {
      fill: transparent;
    }

    :global(.checkmark) {
      fill: $purple;
    }
  }

  &.large {
    flex-basis: $spacer * 3;
    height: $spacer * 3;
    width: $spacer * 3;

    :global(svg) {
      height: $spacer * 3;
      width: $spacer * 3;
    }
  }
}
