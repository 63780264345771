@import '$extensionSrc/sass/consts';

.StylableFileInput {
  .hidden-input {
    display: none;
  }
}

.MetricsDataUpload {
  .upload-tool-wrapper {
    display: flex;
    flex-direction: row;

    .upload-tool-side,
    .display-side {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 20px;
    }

    .display-side {
      border-left: 1px solid $black;
      max-height: 300px;
      overflow: scroll;
    }

    .drop-zone {
      border: 2px dashed $black;
      cursor: pointer;
      height: 100px;
      text-align: center;
      width: 300px;

      .clickable {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.TwoColumnDateValueTable {
  table,
  th,
  td {
    border: 1px solid $black;
    border-collapse: collapse;
  }

  th,
  td {
    box-sizing: content-box;
    padding: 4px;
    width: 120px;
  }

  .even-row {
    background: $lightestGray;
  }

  .odd-row {
    background: $white;
  }

  .row-value,
  .header-value {
    text-align: right;
  }
}
