@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/fonts';

// https://getbootstrap.com/docs/4.3/getting-started/theming/

$link-color: #5b1dc2;
$link-hover-color: #3b1a7c;

$theme-colors: (
  'primary': $purple,
  'success': $green,
  'danger': $red,
  'warning': #cec46e,
  'dark': $darkGray,
  'light': $white,
);

// Bootstrap required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Bootstrap additional packages
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/utilities';

@import '$extensionSrc/sass/responsive';
@import '$extensionSrc/sass/typography';

.PrivacyParty {
  div,
  span {
    font-family: $fontFamily;
  }

  .navbar {
    background: $white;
    z-index: 1400;
  }
}
