@import './consts';
@import './responsive';

@mixin bold() {
  font-weight: 600;
}

// -- Display -- //

.display1 {
  font-size: 48px;
  font-weight: normal;
  line-height: 125%;

  &.bold,
  .bold {
    @include bold;
  }

  @include media-breakpoint-down(sm) {
    font-size: 30px;
    line-height: 150%;
  }
}

.display2 {
  font-size: 40px;
  font-weight: normal;
  line-height: 125%;

  &.bold,
  .bold {
    @include bold;
  }

  @include media-breakpoint-down(sm) {
    font-size: 27px;
    line-height: 150%;
  }
}

.display3 {
  font-size: 36px;
  font-weight: normal;
  line-height: 125%;

  &.bold,
  .bold {
    @include bold;
  }

  @include media-breakpoint-down(sm) {
    font-size: 24px;
    line-height: 150%;
  }
}

// -- Heading -- //

@mixin heading1 {
  font-size: 24px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.heading1 {
  @include heading1;
}

@mixin heading2 {
  font-size: 20px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.heading2 {
  @include heading2;
}

@mixin heading3 {
  font-size: 18px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.heading3 {
  @include heading3;
}

@mixin heading4 {
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.heading4 {
  @include heading4;
}

// -- Subheading -- //

.subheading1 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 150%;
  text-transform: uppercase;
}

.subheading2 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 150%;
  text-transform: uppercase;
}

.subheading3 {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 150%;
  text-transform: uppercase;
}

// -- Body -- //

@mixin body1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.body1,
%body1 {
  @include body1;
}

@mixin body2 {
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.body2 {
  @include body2;
}

@mixin body3 {
  font-family: $fontFamily;
  font-size: 12px;
  font-weight: normal;
  line-height: 150%;

  &.bold,
  .bold {
    @include bold;
  }
}

.body3,
%body3 {
  @include body3;
}

// -- Interactive -- //

.button1 {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 100%;
  text-transform: uppercase;
}

.button2 {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 100%;
  text-transform: uppercase;
}

.link1,
%link1 {
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;
  text-decoration-line: underline;

  &.bold,
  .bold {
    @include bold;
  }
}

.link2 {
  font-size: 14px;
  font-weight: normal;
  line-height: 150%;
  text-decoration-line: underline;

  &.bold,
  .bold {
    @include bold;
  }
}

.link3,
%link3 {
  font-size: 12px;
  font-weight: normal;
  line-height: 150%;
  text-decoration-line: underline;

  &.bold,
  .bold {
    @include bold;
  }
}

.small-label1 {
  font-size: 12px;
  font-weight: bold;
  line-height: 100%;
  text-transform: uppercase;
}

.small-label2 {
  font-size: 10px;
  font-weight: bold;
  line-height: 100%;
  text-transform: uppercase;
}

.cursor-pointer {
  cursor: pointer;
}

// -- Adjustments -- //

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.no-text-transform {
  text-transform: none;
}

.light-gray {
  color: $lightGray;
}

.lighter-gray {
  color: $lighterGray;
}

.lightest-gray {
  color: $lightestGray;
}

.dark-gray {
  color: $darkGray;
}

.darker-gray {
  color: $darkerGray;
}

.darkest-gray {
  color: $darkestGray;
}

.purple {
  color: $purple;
}

.dark-purple {
  color: $darkPurple;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.red {
  color: $red;
}

.darker-red {
  color: $darkerRed;
}

@mixin center {
  text-align: center;
}

.center {
  @include center;
}

.right {
  text-align: right;
}

.normal {
  font-weight: normal;
}
