@import '../sass/consts';

.toDoCheckbox {
  display: flex;
}

.icon {
  flex: none;
  height: $spacer * 2.5;
  margin-right: $spacer;
  min-width: $spacer * 2.5;

  // Default is white interior, unchecked, gray outline
  :global(.interior) {
    fill: $white;
  }

  :global(.checkmark) {
    display: none;
  }

  :global(.border) {
    stroke: $lightGray;
  }

  // Make border purple if hover
  &:hover {
    cursor: pointer;

    :global(.border) {
      stroke: $purple;
    }
  }

  // Show checkmark if we also have class "checked"
  &.checked :global(.checkmark) {
    display: unset;
  }

  // If disabled and checked, use a lighter purple for the checkmark
  &.disabled.checked :global(.checkmark) {
    fill: $lighterPurple;
  }

  // When disabled, light gray interior & no hover action
  &.disabled :global(.interior) {
    fill: $lightestGray;
  }

  &.disabled:hover {
    cursor: auto;
  }

  &.disabled:hover :global(.border) {
    stroke: $lightGray;
  }
}
