@import '$extensionSrc/sass/consts';

.protipBanner {
  align-items: center;
  background: $foamLightestPurple;
  border-radius: $spacer * 0.5;
  display: flex;
  gap: $spacer * 2;
  padding: $spacer * 2;

  &.blue {
    background: $bluePurple;
    gap: $spacer * 1.5;
    padding: $spacer * 1.5;
  }
}

.icon {
  max-height: $spacer * 4;
  max-width: $spacer * 4;
  min-height: $spacer * 4;
  min-width: $spacer * 4;
}
