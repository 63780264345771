@import '$extensionSrc/sass/consts';

.selectDeleteTimeframe {
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;

  select {
    background-color: $white;
    border: 1px solid $lighterGray;
    border-radius: 0.5 * $spacer;
    height: 37px;
    padding: $spacer;

    &:hover {
      background-color: $almostWhite;
    }
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;
}

.selectDate {
  width: 100%;

  .dateDropdown {
    width: 100%;
  }
}

.monthYearSelector {
  align-items: center;
  display: flex;
  gap: $spacer;
  width: 100%;

  select {
    flex-grow: 1;
  }
}

.rollingDeleteOption {
  accent-color: $purple;
  display: flex;
  gap: 2.25 * $spacer;
}
