@import '$extensionSrc/sass/recommendation-card-body';
@import '$extensionSrc/sass/tooltip';

.AutomatedBody {
  @include recommendation-transition;

  .untruncate-button {
    align-items: center;
    background: $white;
    border: 1px solid $lightestGray;
    border-radius: 28px;
    bottom: 17 * $spacer;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5 * $spacer 1.5 * $spacer 0.5 * $spacer 2 * $spacer;
    position: absolute;
    right: 0;
    width: 19 * $spacer;

    .caret-icon {
      stroke: $black;
      transform: rotate(180deg);
      width: 2.2 * $spacer;
    }

    &:hover {
      border: 1px solid $purple;
      color: $purple;

      .caret-icon {
        stroke: $purple;
      }
    }
  }

  .recommendation-body {
    @include recommendation-body;

    &.truncated {
      mask-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.95) 0%,
        transparent
      );
    }

    &.no-title {
      padding-top: $spacer;
    }

    .recommendation-body-fulfilled {
      border-top: 1px solid $lighterGray;

      .recommendation-body-fulfilled-toggle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: $spacer;
        padding-top: 2 * $spacer;

        .expand-fulfilled-icon {
          width: 2 * $spacer;
        }
      }

      &:first-child {
        border-top: unset;
        margin-top: -1 * $spacer;
      }
    }
  }

  .recommendation-footer {
    @include recommendation-footer;

    &.truncated {
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    }

    .space-between {
      justify-content: space-between;
    }

    .warning-icon {
      height: 20px;
      margin-right: $spacer;
    }

    .timestamp {
      &:empty {
        display: none;
      }
    }

    .tooltip-text {
      @include tooltip-text('right', 'above');
      bottom: 10 * $spacer;
      margin-bottom: 0.5 * $spacer;
      max-width: 40 * $spacer;
      right: 2 * $spacer;
      white-space: wrap;
      z-index: 10;
    }

    &:hover {
      .tooltip-text {
        visibility: visible;
      }
    }
  }
}
