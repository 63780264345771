@import '$extensionSrc/sass/consts';

.unfriendingCount {
  align-items: center;
  display: flex;
  gap: $spacer;
  justify-content: flex-end;
}

.icon {
  height: $spacer * 2.5;
  width: $spacer * 2.5;
}
