@import './consts';

@mixin recommendation-transition {
  max-height: 5000px; /*hacky way to get card height to animate*/
  opacity: 1;
  transition:
    opacity 0.5s ease-in,
    max-height 0.5s ease-in;

  &.fade {
    max-height: 60px;
    opacity: 0;
  }
}

@mixin recommendation-body {
  color: $darkestGray;
  padding: 2 * $spacer;

  .recommendation-body-title {
    margin-bottom: 2 * $spacer;

    &:empty {
      display: none;
    }
  }

  > * {
    margin-bottom: 2 * $spacer;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin recommendation-footer {
  background-color: $almostWhite;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  padding: 2 * $spacer;

  &:empty {
    display: none;
  }

  .footer-description {
    align-items: center;
    border-bottom: 1px solid $lighterGray;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2 * $spacer;
    padding-bottom: 12px;
    text-align: center;
    width: 100%;

    &:empty {
      display: none;
    }

    &.no-buttons-underneath {
      border-bottom: unset;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  .btn {
    margin-bottom: $spacer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .footer-buttons {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .btn {
      margin-bottom: $spacer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.glow {
      :first-child.btn {
        border: 2px solid $yellow;
        box-shadow: 0 0 12px 0 $yellow;
      }
    }

    :first-child.btn {
      border: 0 solid transparent;
      transition:
        box-shadow ease-in-out 1s,
        border-color ease-in-out 1s,
        border-width ease-in-out 1s;
    }

    &.mark-as-done-row,
    .mark-as-done-row {
      display: flex;
      flex-direction: row;
      gap: 2 * $spacer;

      :first-child {
        flex-grow: 2;
      }

      :last-child {
        flex-grow: 1;
      }
    }
  }
}
