@import '$extensionSrc/sass/consts';

.StepRow {
  .step-icon {
    height: 20px;
    margin-right: $spacer;
    min-width: 20px;
  }

  .step-label {
    &.todo-or-done {
      color: $black;
    }

    &.skipped {
      color: $darkestGray;
    }

    .SidebarLink {
      display: contents;

      .link-text {
        border-bottom: 1px solid $darkerBlue;
        color: $black;
        display: inline;
        padding: 0 1px;

        &:visited {
          color: $black;
        }

        &:hover {
          background: $lightBlue;
          color: $black;
          text-decoration: none;
        }
      }

      .link-arrow {
        display: inline;
        height: 16px;
        width: 16px;

        path {
          fill: $black;
          stroke: $black;
        }
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .coming-soon-text {
    margin-top: 4px;
  }
}
