@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/card';
@import '$extensionSrc/sass/tooltip';

@keyframes confettiAnimation {
  0% {
    opacity: 0;
    transform: translate(-32px, 74px);
    width: 64px;
  }

  10% {
    opacity: 1;
    transform: translate(-48px, -8px);
    width: 96px;
  }

  100% {
    opacity: 0;
    transform: translate(-64px, 74px);
    width: 128px;
  }
}

.RecommendationCard {
  @include sidebar-card;
  overflow: hidden;

  position: relative;

  .confetti {
    animation: confettiAnimation 1.5s ease-in-out;
    flex-shrink: 0;
    height: 44px;
    left: calc(50%);
    position: absolute;
  }

  .anchor {
    // Very hacky :( assumes positioning inside Sidebar with header
    // https://stackoverflow.com/questions/17534661/make-anchor-link-go-some-pixels-above-where-its-linked-to
    position: absolute;
    top: -1 * $sidebarHeaderHeight - 13px;
  }

  .recommendation-title-wrapper {
    border-bottom-color: $lighterGray;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding: 2 * $spacer;

    .recommendation-title {
      align-items: start;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .title {
        align-items: baseline;
        background-color: transparent;
        display: flex;
        transition: background-color ease-in-out 1s;

        .title-text {
          width: 35 * $spacer;
        }

        .share-url {
          margin-left: $spacer;

          .link-icon {
            cursor: pointer;
            height: 3 * $spacer;
            padding-bottom: 0.5 * $spacer;
          }
        }

        &.glow {
          background-color: $yellow;
        }
      }

      .toggle-explanation-button {
        padding-top: 0.5 * $spacer;

        .toggle-explanation-icon {
          cursor: pointer;
          height: 2.5 * $spacer;
          stroke: $lightGray;

          &:hover {
            stroke: $darkGray;
          }
        }
      }
    }

    .recommendation-explanation-text {
      overflow: hidden;

      &.initial {
        height: 0;
      }

      &.hide {
        max-height: 0;
      }

      &.show {
        max-height: 100%;
        padding-top: $spacer;
      }

      span {
        color: $darkestGray;
      }

      p:last-child {
        margin-bottom: 0.5 * $spacer;
      }
    }

    .concern-tags {
      display: flex;
      flex-direction: row;
      gap: $spacer * 0.5;
    }

    .SidebarLink {
      display: contents;

      .link-text {
        border-bottom: 1px solid $darkerBlue;
        color: $black;
        display: inline;
        padding: 0 1px;

        &:visited {
          color: $black;
        }

        &:hover {
          background: $lightBlue;
          color: $black;
          text-decoration: none;
        }
      }

      .link-arrow {
        display: inline;
        height: 16px;
        width: 16px;

        path {
          fill: $black;
          stroke: $black;
        }
      }
    }
  }

  &.facebook-photo-untagging {
    .recommendation-title-wrapper {
      border-bottom: 0;
    }
  }

  .not-clickable {
    cursor: default;
  }
}
