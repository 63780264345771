@import '$extensionSrc/sass/recommendation-card-body';

.ManualBody {
  @include recommendation-transition;

  .recommendation-body {
    @include recommendation-body;

    p {
      margin: 0;
    }
  }

  .recommendation-footer {
    @include recommendation-footer;

    .checkmark-complete-icon {
      height: 18px;
      margin-right: 0.5 * $spacer;
    }

    .space-between {
      justify-content: space-between;
    }
  }
}
