@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/recommendation-card-body';
@import '$extensionSrc/sass/tooltip';

.FbPhotoUntaggingBody {
  @include recommendation-transition;

  .recommendation-body {
    @include recommendation-body;
    padding-top: 0;

    .recommendation-body-title {
      border-top: 1px solid $lighterGray;
      display: flex;
      padding-top: $spacer * 2;
      position: relative;
    }

    .check-photos-icon {
      cursor: pointer;
      position: absolute;
      right: 0;
      width: 24px;
    }

    .photo-count {
      text-align: center;
    }

    .load-more-photos {
      display: flex;
      flex-direction: column;
      margin: 0 2 * $spacer;
    }
  }

  .recommendation-footer {
    @include recommendation-footer;

    .footer-description {
      gap: 4px;
      text-align: left;
    }

    .space-between {
      justify-content: space-between;
    }

    .tooltip-text {
      @include tooltip-text('right', 'above');
      bottom: $spacer * 8;
      max-width: $spacer * 40;
      right: $spacer * 2;
      white-space: wrap;
      z-index: 10;
    }

    &:hover {
      .tooltip-text {
        visibility: visible;
      }
    }
  }

  .checkmark-complete-icon {
    margin-right: 0.5 * $spacer;
    min-width: 18px;
    width: 18px;
  }

  .not-started-yet {
    margin-bottom: 0;
    margin-top: $spacer * -2;
    padding-left: $spacer * 2;
    padding-right: $spacer * 2;

    img {
      display: block;
      height: auto;
      width: 100%;
    }

    .instructions {
      margin-top: $spacer * -3;
      text-align: center;

      .arrow {
        color: $purple;
      }
    }
  }
}
