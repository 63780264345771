@import '$extensionSrc/sass/consts';

.selectTwitterPost {
  display: flex;
  flex-direction: column;
  gap: 2 * $spacer;
}

.errorHelper {
  background-color: $lighterYellow;
  padding: $spacer;
  text-align: center;
}

.postInfo {
  border: 1px dashed $lighterGray;
  border-radius: 0.5 * $spacer;
  display: flex;
  height: 7 * $spacer;
  justify-content: space-between;
  overflow: hidden;
  padding: $spacer;
  vertical-align: middle;
  width: 100%;

  &.selected {
    border: 1px solid $lighterGray;
  }
}

.loadingContent {
  height: 140px;
}

.postContent {
  height: 2 * $spacer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 31 * $spacer;
}

.postRepostCount {
  align-items: center;
  display: flex;
  gap: 0.25 * $spacer;
  padding: $spacer 0;
}

.repostIcon {
  height: 2 * $spacer;
  width: 2 * $spacer;
}

.noPost {
  color: $lightGray;

  path {
    fill: $lightGray;
  }
}

.hasPost {
  color: $black;

  path {
    fill: $black;
  }
}
