@import './consts';

@mixin tooltip-text($alignment, $verticalPosition) {
  background-color: $moreTransparentBlack;
  border-radius: 4px;
  color: $white;
  padding: 0.5 * $spacer $spacer;
  position: absolute;
  text-align: center;
  visibility: hidden;
  white-space: nowrap;
  z-index: 10;

  @if $alignment == 'left' {
    left: 0;
  }

  @if $alignment == 'right' {
    right: 0;
  }

  @if $alignment == 'center' {
    left: 0;
    margin-inline: auto;
    right: 0;
    width: fit-content;
  }

  @if $verticalPosition == 'below' {
    top: 100%;
  }

  @if $verticalPosition == 'above' {
    bottom: 100%;
  }
}
