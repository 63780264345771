@import '$extensionSrc/sass/consts';
@import '$extensionSrc/sass/tooltip';

.recommendation-header {
  align-items: center;
  background-color: $lightestPurple;
  color: $black;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  gap: 0;
  height: 5.5 * $spacer;
  justify-content: space-between;
  line-height: 21px;
  padding: $spacer 0 $spacer $spacer;
  transition: background-color 1s ease-out;

  .concern-tags {
    align-items: center;
    display: flex;
    gap: $spacer;

    .concern-tag {
      align-items: center;
      border-radius: 5 * $spacer;
      display: flex;
      gap: $spacer * 0.5;
      height: 3.25 * $spacer;
      padding: 0.5 * $spacer 1.5 * $spacer;
    }

    .concern-tag-icon {
      height: $spacer * 2.25;
      width: $spacer * 2.25;

      svg {
        color: $black;
        display: block;
      }
    }

    .additional {
      position: relative;

      .prompt {
        align-items: center;
        cursor: default;
        display: flex;
        gap: $spacer * 0.5;
      }

      .plus-sign {
        padding-right: $spacer * 0.5;
      }

      .tooltip-text {
        @include tooltip-text('left', 'below');
        margin-top: 0.625 * $spacer;
        text-align: left;
      }

      .prompt:focus-visible + .tooltip-text,
      .prompt:hover + .tooltip-text {
        visibility: visible;
      }
    }
  }

  .recommendation-header-right {
    .recommendation-relevance {
      align-items: center;
      color: $darkerGray;
      gap: 6px;
      padding-right: $spacer;

      .less-relevant-icon {
        height: 12px;
        width: 12px;
      }

      .tooltip-text {
        @include tooltip-text('right', 'below');
        margin-bottom: 0.5 * $spacer;
        right: 60px;
        top: 40px;
        white-space: wrap;
        width: 232px;
        z-index: 5;
      }

      &:hover {
        .tooltip-text {
          visibility: visible;
        }
      }
    }

    .recommendation-header-tab {
      align-items: center;
      border-left: 1px solid $lighterGray;
      display: inline-flex;
      padding: 10px 13px;

      &:only-child {
        margin-left: auto;
      }

      .recommendation-header-tab-rescan-icon {
        height: 18px;
        width: 18px;
      }

      .tooltip-text {
        @include tooltip-text('right', 'below');
        margin-bottom: 0.5 * $spacer;
        max-width: 28 * $spacer;
        right: $spacer;
        top: 5 * $spacer;
        white-space: wrap;
        z-index: 10;
      }

      &:hover {
        .tooltip-text {
          visibility: visible;
        }
      }
    }
  }

  &.incomplete {
    background-color: $lightestPurple;

    .concern-tags {
      .concern-tag {
        background: $lighterPurple;
      }
    }

    .recommendation-header-tab {
      .recommendation-header-tab-rescan-icon {
        path {
          fill: $purple;
        }
      }

      &:hover {
        background-color: $lighterPurple;
      }
    }
  }

  &.updated,
  &.fulfilled {
    background-color: $lighterGreen;

    .concern-tags {
      .concern-tag {
        background: $lightGreen;
      }
    }

    .recommendation-header-tab {
      .recommendation-header-tab-rescan-icon {
        path {
          fill: $darkGreen;
        }
      }

      &:hover {
        background-color: $lightGreen;
      }
    }
  }

  &.skipped,
  &.less-relevant {
    background-color: $almostWhite;

    .concern-tags {
      .concern-tag {
        background: $lighterGray;
      }
    }

    .recommendation-header-tab {
      .recommendation-header-tab-rescan-icon {
        path {
          fill: $darkGray;
        }
      }

      &:hover {
        background-color: $lightestGray;
      }
    }
  }

  &.task {
    background-color: $lighterOrange;

    .concern-tags {
      .concern-tag {
        background: $lightOrange;

        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }
      }
    }

    .recommendation-header-tab {
      .recommendation-header-tab-rescan-icon {
        path {
          fill: $darkOrange;
        }
      }

      &:hover {
        background: $lightOrange;
      }
    }
  }
}
