@import '$extensionSrc/sass/consts';

.RecommendationCardPreview {
  height: 800px;
  overflow-y: scroll;
  width: $sidebarWidth;

  .recommendation-card-container {
    margin-bottom: $spacer;
  }
}
