@import '../sass/consts';

.Toast {
  align-items: center;
  border-radius: 0.5 * $spacer;
  bottom: 100%;
  box-shadow: 0 24px 24px 0 $moreTransparentGray;
  display: flex;
  gap: 10px;
  justify-content: center;
  left: 50%;
  max-width: 368px;
  min-height: 28px;
  opacity: 0;
  padding: 12px 16px;
  position: absolute;
  transform: translate(-50%);
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
  z-index: $toastZIndex;

  .close-icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    flex-shrink: 0;
    gap: $spacer;
    height: 28px;
    justify-content: center;
    padding: 5px;
    width: 28px;

    path {
      fill: $lightGray;
    }

    &:hover {
      background: $moreTransparentGray;
      cursor: pointer;

      path {
        fill: $darkerGray;
      }
    }
  }

  .text {
    color: $black;
    flex: 1 0 0;
    flex-shrink: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
  }

  &.green {
    background: $lightGreen;
    border: 1px solid $green;
  }

  &.red {
    background: $lighterRed;
    border: 1px solid $red;
  }

  &.yellow {
    background: $lighterYellow;
    border: 1px solid $yellow;
  }

  &.in {
    opacity: 1;
    transform: translate(-50%, calc(100% + 24px));
  }
}
