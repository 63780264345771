@import '$extensionSrc/sass/consts';

.friendSet {
  align-items: center;
  display: flex;
  justify-content: space-between;

  button {
    min-width: $spacer * 17;
  }
}
