@import '$extensionSrc/sass/consts';

.friendSets {
  padding-top: $spacer * 2;
}

.heading {
  border-bottom: 1px solid $lighterGray;
  display: flex;
  justify-content: space-between;
  padding-bottom: $spacer;
  padding-top: $spacer;
}

.sets {
  max-height: $spacer * 17.75;
  padding-top: $spacer;
  position: relative;

  &.allFriendsAreLoaded {
    max-height: $spacer * 29.75;
  }
}

.scroller {
  display: flex;
  flex-direction: column;
  gap: $spacer;
  max-height: $spacer * 16.75;
  overflow-y: scroll;
  padding-bottom: $spacer * 4.25;

  &.allFriendsAreLoaded {
    max-height: $spacer * 28.75;
    padding-bottom: 0;
  }
}

.loadAllFriends {
  align-items: center;
  background: $white;
  border: 1px solid $lighterGray;
  bottom: 0;
  display: flex;
  gap: $spacer;
  height: $spacer * 3.25;
  justify-content: center;
  left: 0;
  position: absolute;
  transition: $transition;
  width: 100%;

  &:hover {
    background: $almostWhite;
    border: 1px solid $lighterPurple;
  }
}
