@import '$extensionSrc/sass/consts';

.subheader {
  align-items: flex-start;
  display: flex;
  margin: 0 0 $spacer;

  .text {
    color: $darkestGray;
    height: 100%;
  }

  .icon {
    align-self: flex-start;
    height: 18px;
    margin: 0 $spacer;
    width: 18px;
  }
}
