@import '$extensionSrc/sass/consts';

.MetricsKeyFunnelTable {
  display: flex;
  flex-direction: column;
  gap: 3 * $spacer;

  .metrics-tables {
    display: flex;
    flex-direction: row;
    margin-top: 2 * $spacer;

    table,
    th,
    td {
      border: 1px solid $black;
      border-collapse: collapse;
    }

    th,
    td {
      box-sizing: content-box;
      height: 30px;
      padding: 4px;
    }

    .fixed-row-header-table {
      background: $lightestGray;
      min-width: 300px;
      table-layout: fixed;
    }

    .data-series-table-container {
      overflow: scroll;

      .data-series-table {
        table-layout: fixed;
        width: 100%;

        .data-series-table-header-item {
          width: 105px;
        }

        th,
        td {
          text-align: right;
        }

        .daily-data {
          background-color: $lightestGray;
          font-size: smaller;
          width: 80px;
        }
      }
    }
  }

  .latest-full-week {
    background-color: $yellow;
  }

  .latest-partial-week {
    background-color: $lighterYellow;
  }

  .data-incomplete {
    color: $red;
    font-style: italic;
  }

  .no-lower-margin {
    margin-bottom: unset;
  }

  .aux-data {
    display: flex;
    flex-direction: row;
    gap: 4 * $spacer;

    .last-updated-box {
      flex-shrink: 0;

      table,
      td {
        border: 1px solid $black;
        border-collapse: collapse;
        padding: 4px;
      }
    }
  }
}
